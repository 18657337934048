import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const URL = `${process.env.PUBLIC_URL}/flowers`
const useStyles = makeStyles((theme) => ({
  logoArea: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginBottom: 15,
  },
  headerArea: {
    marginTop: 25,
    textAlign: 'center',
    padding: 15,
    fontWeight: 500,
    color: '#888',
  },

  media: {
    height: 140,
  },
  priceIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  colorCheckbox: {
    marginRight: theme.spacing(1),
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flowerCard: {
    maxWidth: 200,
    margin: 'auto',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedCard: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const flowerTypes = [
  {
    name: 'Geraniums',
    colors: ['red', 'white', 'pink'],
    image: `${URL}/geranium3.jfif`,
    priceLevel: 1,
  },
  {
    name: 'Petunias',
    colors: ['red', 'white', 'pink', 'blue', 'purple'],
    image: `${URL}/petunia.jpg`,
    priceLevel: 2,
  },
  {
    name: 'Cyclamen',
    colors: ['red', 'white', 'pink'],
    image: `${URL}/cyclamen.jpg`,
    priceLevel: 3,
  },
  {
    name: 'Pansies',
    colors: ['red', 'white', 'pink', 'blue', 'purple'],
    image: `${URL}/pansies.jpg`,
    priceLevel: 2,
  },
  {
    name: 'Snapdragons',
    colors: ['red', 'white', 'pink', 'blue', 'orange'],
    image: `${URL}/snapdragons.jpg`,
    priceLevel: 2,
  },
  {
    name: 'Trailing Bacopa',
    colors: ['blue', 'white'],
    image: `${URL}/bacopa.jpeg`,
    priceLevel: 2,
  },
  {
    name: 'Trailing Alyssum',
    colors: ['white'],
    image: `${URL}/trailingallysum.webp`,
    priceLevel: 2,
  },
  {
    name: 'Lobelia',
    colors: ['blue'],
    image: `${URL}/lobelia.webp`,
    priceLevel: 2,
  },
  {
    name: 'Kalanchoe',
    colors: ['red', 'orange', 'white', 'yellow'],
    image: `${URL}/kalanchoe.webp`,
    priceLevel: 2,
  },
];

const WinterFlowersSelection = () => {
  const classes = useStyles();
  const [wantFlowers, setWantFlowers] = useState(false);
  const [selectedFlowers, setSelectedFlowers] = useState([]);
  const [sameAsLastYear, setSameAsLastYear] = useState(false);

  const handleFlowerSelect = (flowerName) => {
    setSelectedFlowers((prev) =>
      prev.includes(flowerName)
        ? prev.filter((name) => name !== flowerName)
        : [...prev, flowerName]
    );
  };

  const renderPriceIndicator = (priceLevel) => {
    return (
      <div className={classes.priceIndicator}>
        {[...Array(priceLevel)].map((_, index) => (
          <span>$</span>
        ))}
      </div>
    );
  };

  const handleCardClick = (event, flowerName) => {
    event.preventDefault();
    handleFlowerSelect(flowerName);
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log({
      wantFlowers,
      selectedFlowers,
      sameAsLastYear,
    });
    alert('Selection submitted!');
  };

  const handleScheduleMeeting = () => {
    // Implement scheduling logic here
    alert('Redirecting to schedule a meeting...');
  };

  const handleRequestQuote = () => {
    // Implement request quote logic here
    alert('Redirecting to request a quote...');
  };

  let logoURL = 'https://dflportal.com/build/logowide2.png';

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <div className={classes.logoArea}>
        <img
          alt="Logo"
          src={`${logoURL}`}
          style={{
            marginLeft: '-100px',
            marginTop: '5px',
            maxHeight: 100,
            maxWidth: 300,
          }}
        />
      </div>
      <Typography variant="h4" className={classes.headerArea} gutterBottom>
        Winter Flowers Selection
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={wantFlowers}
                onChange={(e) => setWantFlowers(e.target.checked)}
                color="primary"
              />
            }
            label="I want flowers this winter"
          />
        </FormGroup>

        {wantFlowers && !sameAsLastYear && (
          <>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: '30px' }}
            >
              Please note: The flower selections below are to gather your preferences for this season.
              While we will do our best to accommodate your choices, specific colors and varieties
              cannot be guaranteed due to seasonal availability and supplier stock. Your selections
              help us understand your preferences so we can create a beautiful arrangement for your
              landscape.
            </Typography>
          <FormControl
            component="fieldset"
            style={{ marginTop: '18px' }}
            fullWidth
          >

            <Typography style={{ marginBottom: '10px' }} component="legend">Select Preferred Flower Varieties</Typography>
            <Grid container spacing={3}>
              {flowerTypes.map((flower) => (
                <Grid item xs={12} sm={6} md={4} key={flower.name}>
                  <Card className={classes.flowerCard}  onClick={(event) => handleCardClick(event, flower.name)}>
                    <CardMedia
                      className={classes.media}
                      image={flower.image}
                      title={flower.name}
                    />
                    <CardContent>
                      <div className={classes.nameRow}>
                        <Typography gutterBottom variant="h6" component="h2">
                          {flower.name}   
                        </Typography>
                        {renderPriceIndicator(flower.priceLevel)}
                      </div>
                      {/* <FormGroup>
                          {flower.colors.map((color) => (
                            <FormControlLabel
                              key={`${flower.name}-${color}`}
                              control={
                                <Checkbox
                                  checked={selectedFlowers[flower.name]?.[color] || false}
                                  onChange={() => handleFlowerSelect(flower.name, color)}
                                  color="primary"
                                  className={classes.colorCheckbox}
                                />
                              }
                              label={color}
                            />
                          ))}
                        </FormGroup> */}
                    
                      <Typography variant="body2" color="textSecondary">
                        Available colors: {flower.colors.join(', ')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFlowers.includes(flower.name)}
                            onChange={() => handleFlowerSelect(flower.name)}
                            onClick={handleCheckboxClick}
                            color="primary"
                          />
                        }
                        label="Select"
                      />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </FormControl>
          </>
        )}

        <FormGroup style={{ marginTop: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sameAsLastYear}
                onChange={(e) => {
                  setSameAsLastYear(e.target.checked);
                  if (e.target.checked) {
                    setSelectedFlowers([]);
                  }
                }}
                color="primary"
              />
            }
            label="Same as Last Year"
          />
        </FormGroup>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '20px', marginRight: '10px' }}
          disabled={!wantFlowers && !sameAsLastYear}
        >
          Submit Selection
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px', marginRight: '10px' }}
          onClick={handleScheduleMeeting}
        >
          Schedule a Meeting
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px' }}
          onClick={handleRequestQuote}
        >
          Request a Quote
        </Button>
      </form>
    </Paper>
  );
};

export default WinterFlowersSelection;
